import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Dropdown from "react-bootstrap/Dropdown"
import DropdownButton from "react-bootstrap/DropdownButton"
import { isSafariLess16 } from "../utils.js"
import * as style from "./open-roles-main.module.css"

const OpenRolesHero = ({ vacancies }) => {
  // return vacancy type info to the items
  let vacType = vacancy => {
    let contractTypeDisplay = ""

    switch (vacancy.cfCareersOpenRoles.openContractType) {
      case "both":
        contractTypeDisplay = "Permanent or Contract"
        break
      case "permanent":
        contractTypeDisplay = "Permanent Position"
        break
      case "contract":
        contractTypeDisplay = "Contract"
        break
      default:
        contractTypeDisplay = "Permanent or Contract"
    }

    return contractTypeDisplay
  }

  // return salary info to the items
  let salaryRange = vacancy => {
    let openSalary = "Competitive"

    if (
      vacancy.cfCareersOpenRoles.openSalary !== "" &&
      vacancy.cfCareersOpenRoles.openSalary !== null &&
      vacancy.cfCareersOpenRoles.openSalary !== undefined
    ) {
      openSalary = vacancy.cfCareersOpenRoles.openSalary
    }

    return openSalary
  }

  // set states for drop down searchs
  const [typeStatus, setTypeStatus] = useState("both")
  const [locStatus, setLocStatus] = useState("all")
  // set state to update available roles on a search
  const [dCount, setDCount] = useState(0)

  // set the initial value of the role counter used to update number of vacancies
  let noVacs = 0

  // run the update on render
  useEffect(() => {
    setDCount(noVacs)
  })

  const TheVacTitle = ({ locationtitle }) => {
    let elLocation

    if (locationtitle == "hybridall") {
      elLocation = "Hybrid - from any of our UK offices"
    } else {
      elLocation =
        locationtitle.charAt(0).toUpperCase() + locationtitle.slice(1)
    }
    return elLocation
  }

  const isSafariV16 = isSafariLess16()

  return (
    <div style={{ overflowX: "hidden" }}>
      <div className="darkgrey_bg mb-4 pt-5 pt-lg-0">
        <div className="container pt-5">
          <div className="row">
            <div
              id="hide-row-when-nav-open"
              className="col-12 col-lg-7 d-flex align-items-center color-white animated-headline mt-5 mt-lg-0"
            >
              <div className="col-12">
                <span className="header-fade-in d-block h4 text-uppercase mb-3">
                  Careers
                </span>
                <div className="animated-headline-wrap">
                  <h1 className="h2 new_style">
                    <span
                      className={`new_style_addit ${
                        isSafariV16 ? "safari_16" : ""
                      }`}
                    >
                      Search Our Open Roles
                    </span>
                  </h1>
                </div>
                <div
                  className="d-flex flex-wrap mt-4 mt-md-5 header-fade-in"
                  style={{
                    zIndex: "1",
                    position: "relative",
                  }}
                >
                  <div className="col-12 col-md-6 pe-0 pe-md-3 dropdown">
                    <DropdownButton
                      id="dropdown-item-button"
                      title={
                        <>
                          <img
                            className="inline-icon"
                            src="/assets/img/icon-case-white.svg"
                            alt=""
                          />{" "}
                          {(typeStatus === "both" && (
                            <span>All Contract Types</span>
                          )) ||
                            (typeStatus === "contract" && (
                              <span>Contract</span>
                            )) ||
                            (typeStatus === "permanent" && (
                              <span>Permanent</span>
                            ))}{" "}
                        </>
                      }
                      className={`${style.btn_drop} dropdown-toggle drop-btn`}
                    >
                      {typeStatus !== "both" && (
                        <Dropdown.Item
                          as="button"
                          onClick={() => setTypeStatus("both")}
                        >
                          All Contract Types
                        </Dropdown.Item>
                      )}
                      {typeStatus !== "permanent" && (
                        <Dropdown.Item
                          as="button"
                          onClick={() => setTypeStatus("permanent")}
                        >
                          Permanent
                        </Dropdown.Item>
                      )}
                      {typeStatus !== "contract" && (
                        <Dropdown.Item
                          as="button"
                          onClick={() => setTypeStatus("contract")}
                        >
                          Contract
                        </Dropdown.Item>
                      )}
                    </DropdownButton>
                  </div>
                  <div className="col-12 col-md-6 pe-0 pe-md-3 dropdown">
                    <DropdownButton
                      id="dropdown-item-button"
                      title={
                        <>
                          <img
                            className="inline-icon"
                            src="/assets/img/icon-compass-white.svg"
                            alt=""
                          />{" "}
                          {(locStatus === "all" && (
                            <span>All Locations</span>
                          )) ||
                            (locStatus === "hybridall" && (
                              <span>Hybrid - from any of our UK offices</span>
                            )) ||
                            (locStatus === "london" && <span>London</span>) ||
                            (locStatus === "edinburgh" && (
                              <span>Edinburgh</span>
                            )) ||
                            (locStatus === "glasgow" && <span>Glasgow</span>) ||
                            (locStatus === "dundee" && <span>Dundee</span>) ||
                            (locStatus === "bristol" && <span>Bristol</span>) ||
                            (locStatus === "cardiff" && <span>Cardiff</span>) ||
                            (locStatus === "scotland" && (
                              <span>Scotland</span>
                            )) ||
                            (locStatus === "remote" && (
                              <span>Remote</span>
                            ))}{" "}
                        </>
                      }
                      className={`${style.btn_drop}
                                  dropdown-toggle
                                  drop-btn`}
                    >
                      {locStatus !== "all" && (
                        <Dropdown.Item
                          as="button"
                          onClick={() => setLocStatus("all")}
                        >
                          All locations
                        </Dropdown.Item>
                      )}
                      {locStatus !== "hybridall" && (
                        <Dropdown.Item
                          as="button"
                          onClick={() => setLocStatus("hybridall")}
                        >
                          Hybrid - from any of our UK offices
                        </Dropdown.Item>
                      )}
                      {locStatus !== "london" && (
                        <Dropdown.Item
                          as="button"
                          onClick={() => setLocStatus("london")}
                        >
                          London
                        </Dropdown.Item>
                      )}
                      {locStatus !== "edinburgh" && (
                        <Dropdown.Item
                          as="button"
                          onClick={() => setLocStatus("edinburgh")}
                        >
                          Edinburgh
                        </Dropdown.Item>
                      )}
                      {locStatus !== "glasgow" && (
                        <Dropdown.Item
                          as="button"
                          onClick={() => setLocStatus("glasgow")}
                        >
                          Glasgow
                        </Dropdown.Item>
                      )}
                      {locStatus !== "dundee" && (
                        <Dropdown.Item
                          as="button"
                          onClick={() => setLocStatus("dundee")}
                        >
                          Dundee
                        </Dropdown.Item>
                      )}
                      {locStatus !== "bristol" && (
                        <Dropdown.Item
                          as="button"
                          onClick={() => setLocStatus("bristol")}
                        >
                          Bristol
                        </Dropdown.Item>
                      )}
                      {locStatus !== "cardiff" && (
                        <Dropdown.Item
                          as="button"
                          onClick={() => setLocStatus("cardiff")}
                        >
                          Cardiff
                        </Dropdown.Item>
                      )}
                      {locStatus !== "scotland" && (
                        <Dropdown.Item
                          as="button"
                          onClick={() => setLocStatus("scotland")}
                        >
                          Scotland
                        </Dropdown.Item>
                      )}
                      {locStatus !== "remote" && (
                        <Dropdown.Item
                          as="button"
                          onClick={() => setLocStatus("remote")}
                        >
                          Remote
                        </Dropdown.Item>
                      )}
                    </DropdownButton>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-5 mt-5 mt-lg-6">
              <div className="d-flex align-items-end">
                <img
                  className={`${style.team_hero} header-fade-in`}
                  src="https://res.cloudinary.com/dvmdv4ttu/image/upload/f_auto/img/hero-d-rom_pzieaf.png"
                  alt="waracle employee"
                />
                <img
                  className={`${style.team_hero} header-fade-in`}
                  src="https://res.cloudinary.com/dvmdv4ttu/image/upload/f_auto/img/new-clare-hero_smlzyz.png"
                  alt="waracle employee"
                  style={{
                    marginLeft: "-90px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row mb-4 mb-lg-5">
          {typeStatus === "both" && locStatus === "all" && (
            <p style={{ color: "#888888" }}>
              {dCount} {dCount === 1 ? "role" : "roles"} currently open.
            </p>
          )}
          {(typeStatus !== "both" || locStatus !== "all") && (
            <p style={{ color: "#888888" }}>
              {dCount} {dCount === 1 ? "role" : "roles"} found matching your
              search.
            </p>
          )}
        </div>
      </div>
      {vacancies.map(
        vacancy =>
          (vacancy.cfCareersOpenRoles.openContractType === typeStatus ||
            typeStatus === "both" ||
            vacancy.cfCareersOpenRoles.openContractType === "both") &&
          (vacancy.cfCareersOpenRoles.openLocation === locStatus ||
            locStatus === "all") && (
            <div key={noVacs++} className="container mb-5">
              <hr className="mb-5" style={{ maxWidth: "180px" }} />
              <div className="row">
                <div className="col-12 col-xl-4 mb-3 mb-xl-0">
                  <Link to={vacancy.uri} title="More Details">
                    <h3 className="lower">{vacancy.title}</h3>
                  </Link>

                  <h5>
                    <TheVacTitle
                      locationtitle={vacancy.cfCareersOpenRoles.openLocation}
                    />
                  </h5>
                  <h5>{vacType(vacancy)}</h5>
                  <h5>{salaryRange(vacancy)}</h5>
                </div>
                <div className="col-12 col-xl-6 pe-0 pe-xl-5 mb-3 mb-xl-0">
                  <p className="mb-xl-0">
                    {vacancy.cfCareersOpenRoles.openIntroText}
                  </p>
                </div>
                <div className="col-12 col-sm-4 col-lg-3 col-xl-2 d-flex flex-column">
                  <Link
                    className="ani_grey_arrow right_aligned mb-4"
                    to={vacancy.uri}
                    state={{ modal: true }}
                    title="Apply Now"
                  >
                    Apply Now
                  </Link>
                  <Link
                    className="ani_grey_arrow right_aligned mt-2"
                    to={vacancy.uri}
                    title="More Details"
                  >
                    More Details
                  </Link>
                </div>
              </div>
            </div>
          )
      )}
    </div>
  )
}

export default OpenRolesHero
